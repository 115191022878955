<template>
	<div id="aqt_two">
		<div v-for="(item1,index) in item" :key="index" class="aqt_two1">
			<div class="aqt_two" @click="oneClick(item1,index)">
				<div class="aqt_two_top">
					<div v-if="index==selectOneTwo" class="aqt_two_top_dian"></div>
					<img :src="require('./../assets/img/'+(index==selectOneTwo ? 'san_open' : 'san_closetwo') +'.png')" alt="">
				</div>
				<div :style="{color:index==selectOneTwo ? '#003461' : '',height:'calc(100% - 2.4rem)'}" class="aqt_two_center aqt_js_dd"><p class="aqt_lang_mn titlefont aqt_js_pp">{{item1.title|U2M}}</p></div>
			</div>
			<transition name="slide-fade">
				<lay-mulu v-if="(index==selectOneTwo ? (item1.child.length ? true : false) : false)" :item="item1.child" @on-child-click="onChildClick" @on-pay="onPay"></lay-mulu>
			</transition>
		</div>
	</div> 
</template>

<script>
	export default {
		components: {
			LayMulu: resolve => {require(['./LayMulu.vue'], resolve);},
		},
		props: {
			item: {
				type: [Array],
				default: [],
			},
		},
		data() {
			return {
				numTwo:0,
				selectOneTwo:-1,
			};
		},
		methods: {
			oneClick(item,index){
				let _this = this
				let nums = ++_this.numTwo
				if(nums > 1 && _this.selectOneTwo == index){
					_this.selectOneTwo = -1
					_this.numTwo = 0
				}else{
					_this.selectOneTwo = index;
				}
				if(_this.selectOneTwo != -1){
					_this.$emit('on-child-click',item,index);
				}
			},
			onChildClick(item,index){
				if(this.selectOneTwo != -1){
					this.$emit('on-child-click',item,index);
				}
			},
			onPay(data){
				this.$emit('on-pay',data);
			}
		},
		mounted(){
			this.$nextTick(_=>{ 
				let ddarr = document.getElementsByClassName('aqt_js_pp')
				let ddarrs = document.getElementsByClassName('aqt_js_dd')
				for (let index = 0; index < ddarr.length; index++) {
					ddarrs[index].style.width = ddarr[index].clientWidth+'px'
				}
			})
		},
	};
</script>


<style lang="less">
#aqt_two{
	display: flex;
	position: relative;
	height:calc(100% - 2rem);
	top:2rem;
	.aqt_two1{
		display: flex;
		height: 100%;
		.slide-fade-enter-active,.slide-fade-leave-active {
			transition: all .3s ease;
		}
		.slide-fade-enter, .slide-fade-leave-to{
			transform: translateX(10px);
			opacity: 0;
		}
		.aqt_two{
			padding:0 0.6rem;
			margin-right:0.6rem;
			.aqt_wj_price{
				height: 2rem;
				img{
					height: 100%;
					display: block;
					margin: auto;
				}
			}
			.aqt_two_top{
				height: 1.8rem;
				.aqt_two_top_dian{
					width: .6rem;
					height: .6rem;
					border-radius: 50%;
					background-color: #F5A623;
					margin: 0 auto .2rem;
				}
				img{
					height: 1rem;
					display: block;
					margin: auto;
				}
			}
			.aqt_two_center{
				margin: 0.6rem auto 0;
				overflow: hidden;
				p{
					margin: 0 auto;
				}
			}
			.aqt_wj_count{
				font-size: 1rem;
				height: 7rem;
				color: #F5A623;
				p{
					margin: 0 auto;
				}
			}
		}
	}
}

</style>
